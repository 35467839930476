<template>
    <div class="p-8 text-xs">
        <div class="rounded-2xl bg-white p-8">
            <div class="">
                    
                    <div class="flex flex-wrap items-center content-between justify-between">
                        <span class="font-semibold text-base">Data Menu</span>
                        <div class="flex">
                            <div  class="text-xs relative" > 
                                <div class="absolute top-2.5 left-2.5">
                                    <unicon name="search" class="" width="14.34px" height="14.33px" fill="#A1A1AA" ></unicon>
                                    
                                </div>

                                <input
                                v-model="keyword"

                                type="text"
                                :class="`block border border-gray-200 w-72 pr-2 pl-8 px-4 rounded-lg text-xs`"
                                name="keyword"
                                ref="keyword"
                                id="keyword"
                                @keyup.enter="searchData()"
                                placeholder="Pencarian" />
                            </div>
                             <div>
                                <button class="text-white px-4 py-2 bg-blue-500 rounded ml-4" @click="$router.push('/menu/add')">Tambah</button>
                            </div>
                        </div>
                    
                    </div>
                     <div class="mt-4">
                        <table class="w-full" >
                            <tbody v-for="item in data" :key="item.id">
                                 <tr  class="border-b border-gray-200 font-semibold hover:bg-gray-100">
                                    <td class="py-4">
                                        <div class="flex items-center">
                                          
                                                <p>{{item.name}}</p>
                                        </div>
                                    </td>
                                    <td>
                                        <span>{{item.url}}</span>
                                    </td>
                                    <td>
                                        <span>{{item.icon}}</span>
                                    </td>
                                    
                                    <td>
                                          <button class="text-white mr-1 h-8 w-8 text-xs inline bg-green-500 rounded" @click="$router.push('menu/edit/'+ item.id)">
                                            <i class="far fa-edit"></i>
                                        </button>
  
                                        <button class="text-white h-8 w-8 text-xs bg-red-500 rounded" @click="openDelete(item.id)">
                                            <i class="far fa-times" ></i>
                                        </button>
                                    </td>
                                </tr>
                                <template v-if="item.Menus.length > 0" class="w-full">
                                    <tr v-for="child in item.Menus" :key="child.id" class="border-b border-gray-200 font-semibold hover:bg-gray-100">
                                        <td class="py-4 px-4">
                                            <div class="flex items-center">
                                            
                                                    <p> - {{child.name}}</p>
                                            </div>
                                        </td>
                                        <td>
                                            <span>{{child.url}}</span>
                                        </td>
                                        <td>
                                            <span>{{child.icon}}</span>
                                        </td>
                                        
                                        <td>
                                            <button class="text-white mr-1 h-8 w-8 text-xs inline bg-green-500 rounded" @click="$router.push('menu/edit/'+ child.id)">
                                                <i class="far fa-edit"></i>
                                            </button>
    
                                            <button class="text-white h-8 w-8 text-xs bg-red-500 rounded" @click="openDelete(child.id)">
                                                <i class="far fa-times" ></i>
                                            </button>
                                        </td>
                                    </tr>
                                </template>
                            </tbody>
                               
                             
                        </table>

                    </div>
                    
                     <deleteComponent v-on:closeModal="closeModal" v-on:deleteUser="deleteData()" v-if="modal_dialog"/>
                    
            </div>
        </div>
    </div>
</template>
<script>
import {mapGetters,mapAction, mapActions} from 'vuex'
import { ValidationProvider,ValidationObserver } from "vee-validate/dist/vee-validate.full";
import globalMixin from '../../mixin/global'
import deleteComponent from '../../components/global/deleteComponent.vue'

export default {
    data: () => ({
         data: [],
        totalRows:100,
        perPage:10,
        limit:8,
        startNumber:1,
        endNumber:8,
        currentPage:1,
        disabled:false,
        modal_dialog:false,
        keyword:'',
        foto:'',
        delete_id:'',
        modal_edit:false,
        default_url:process.env.VUE_APP_IMAGES
    }),
    mixins:[globalMixin],
    methods:{
        getData(){
            this.axios.get('v1/menu?page=' + this.currentPage + `&keyword=${this.keyword}`,this.config)
            .then((ress) => {
                this.data = ress.data.rows
                this.totalRows = ress.data.count
                
            })
             .catch((err) => {
                this.errorResponse(err)
            })
        },
        openDelete(id) {
            this.delete_id = id
            this.closeModal()
        },
       
        deleteData(){
            this.axios.delete('v1/menu/' + this.delete_id,this.config)
            .then((ress) => {
                 this.closeModal()
                 this.getData()
                  this.$snack.success({
                    text: ress.data.message,

                })
            })
        },
         closeModal() {
            
            this.modal_dialog = !this.modal_dialog
        },
    
    },
    computed:{
        ...mapGetters({
            token:'auth/token',
        }),
       
    },
    created() {
        this.getData()
    },
    components:{
        ValidationProvider,ValidationObserver,deleteComponent
    }
}
</script>